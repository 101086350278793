// Expects https://github.com/fnando/i18n-js to be installed and I18n to be defined globally.
/* eslint no-undef: 0 */
export default {
  data() {
    return {
      i18nScope: null
    }
  },
  methods: {
    t(...args) {
      if (this.i18nScope && args[0]) {
        args[0] = [this.i18nScope, args[0]]
      }
      return I18n.t(...args)
    },
    currentLocale: () => I18n.currentLocale()
  }
}
